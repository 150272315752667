import Form from './modules/Form.js';
import Toggler from './modules/Toggler.js';
import Slider from './modules/Slider.js';
import Map from './modules/Map.js';
import Modal from './modules/Modal.js';

class App {
  constructor() {
    svg4everybody();

    new Form();
    new Toggler();
    new Slider();
    new Map();
    new Modal();

    this.gallery();
  }

  gallery() {
    const elems = Array.from(document.querySelectorAll('.js-gallery'));

    if (elems.length == 0) return;

    elems.forEach(el => {
      lightGallery(el, {
        selector: 'a',
        download: false,
        loop: false
      });
    })
  }
};

document.addEventListener('DOMContentLoaded', () => {
  new App();
});
