class Toggler {

  constructor() {
    const clickout = new clickOut({
      className: '.js-clickout', // class name where outside click
      activeClass: 'is-active', // class name for delete
      after: elements => {
        // after click
      }
    });

    const toggler = new Unitoggle({
      onOpen: tab => {
        // After open callback
        // Mobile Menu
        if (tab.id == 'mob-menu') {
          this.blockScroll();
          const offsetTop = document.querySelector('#header').offsetHeight;
          tab.style.paddingTop = offsetTop + 'px';
          document.querySelector('#header').classList.add('is-active');
        }

        // Accordion Docs
        if (tab.classList.contains('is-doc-tab')) {
          const wrap = tab.closest('.js-acc');
          if (wrap) {
            wrap.classList.add('is-active');
          }
        }

        // Project Menu
        if (tab.id == 'page-nav') {
          const wrap = tab.closest('.js-mob-dropdown');
          wrap.classList.add('is-active');
        }

        if (tab.hasAttribute('data-name')) {
          const dropdown_el = document.querySelector(tab.getAttribute('data-el'));
          const name_el = dropdown_el.querySelector('.js-name');

          name_el.innerHTML = tab.getAttribute('data-name');

          dropdown_el.classList.remove('is-active');
          dropdown_el.querySelector('.js-mob-dropdown-btn').classList.remove('is-active');
          dropdown_el.querySelector('.js-mob-dropdown-list').classList.remove('is-active');
        }

        if (tab.id == 'tab-desc') {
          setTimeout(() => {
            AppSlGallery.forEach(item => {
              item.update();
            });
          }, 300);
        }

        if (tab.id == 'tab-process') {
          setTimeout(() => {
            AppSlProjectProcess.update();
          }, 300);
        }

        if (tab.classList.contains('js-tab-plan') || tab.id == 'tab-plans') {
          setTimeout(() => {
            AppSlPlan.forEach(item => {
              item.update();
            });
          }, 400);
        }
      },
      onClose: tab => {
        // After close callback
        this.blockScroll('off');

        if (tab.id == 'mob-menu') {
          document.querySelector('#header').classList.remove('is-active');
        }

        if (tab.classList.contains('is-doc-tab')) {
          const wrap = tab.closest('.js-acc');
          if (wrap) {
            wrap.classList.remove('is-active');
          }
        }

        if (tab.id == 'page-nav') {
          const wrap = tab.closest('.js-mob-dropdown');
          wrap.classList.remove('is-active');
        }
      }
    });
  }

  blockScroll( status ) {
    if ( status == 'off' ) {
      document.documentElement.classList.remove('is-no-scroll');
      return;
    }

    document.documentElement.classList.add('is-no-scroll');
  }

};

export default Toggler;
