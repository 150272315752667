class Modal {
  constructor() {
    flatpickr('#modal-consult-calendar', {
      locale: 'ru',
      inline: true,
      dateFormat: 'd.m.Y',
      minDate: 'today',
      defaultDate: 'today'
    });

    const modal = new Unimodal({
      onOpen: ( modal, button ) => {
        /* ... do your staff here ... */
      },
      onClose: ( modal ) => {
        /* ... do your staff here ... */
      }
    });
  }
}

export default Modal;
