class Map {
  constructor() {
    const elems = document.querySelectorAll('.js-map');

    if (elems.length == 0) return;

    ymaps.ready(() => {
      elems.forEach(el => {
        const center = JSON.parse(el.getAttribute('data-center'));
        const mark = JSON.parse(el.getAttribute('data-mark'));
        const icon = el.getAttribute('data-icon');
        const icon_size = JSON.parse(el.getAttribute('data-icon-size'));
        const zoom = el.getAttribute('data-zoom');
        const hint = el.getAttribute('data-hint');

        const map = new ymaps.Map(el, {
          center: center,
          zoom: (zoom) ? zoom : 16,
          controls: ['typeSelector', 'fullscreenControl', 'zoomControl'],
        });

        const map_mark = new ymaps.Placemark(mark, {
          balloonContent: (hint) ? hint : ''
        }, {
          iconLayout: 'default#image',
          iconImageHref: icon,
          iconImageSize: icon_size,
          iconImageOffset: [-(icon_size[0] / 2), -(icon_size[1])],
          hideIconOnBalloonOpen: false,
          balloonOffset: [0, -(icon_size[1])]
        });

        map.geoObjects.add(map_mark);

        if (hint) {
          map_mark.balloon.open(mark, hint, {
            offset: [0, -(icon_size[1])]
          });
        }
      });
    });
  }
}

export default Map;
