class Slider {
  constructor() {
    this.mainHero();

    this.slProjects();

    this.slBanks();

    this.slNews();

    this.slGallery();

    this.slProcess();

    this.slPlans();
  }

  mainHero() {
    const el = document.querySelector('#sl-main-hero');

    if (!el) return;

    if (window.innerWidth < 1025) {
      el.style.height = window.innerHeight + 'px';
    }

    const sl = new Swiper(el, {
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.js-next',
        prevEl: '.js-prev',
        disabledClass: 'is-disabled'
      },
      pagination: {
        el: '.js-pagination',
        type: 'bullets',
        bulletActiveClass: 'is-active',
        bulletClass: 'bullet',
        currentClass: 'is-current',
        clickable: true,
        modifierClass: 'bullets-'
      },
    });
  }

  slProjects() {
    const el = document.querySelector('#sl-projects');

    if (!el) return;

    const activateSlide = sw => {
      const arr = [sw.snapIndex, (sw.snapIndex + 1), (sw.snapIndex + 2)];

      sw.slides.forEach((item, index) => {

        if (!arr.includes(index)) {
          item.classList.remove('is-active');
        } else {
          item.classList.add('is-active');
        }
      });
    };

    const btn_prev = el.querySelector('.js-prev');
    const btn_next = el.querySelector('.js-next');
    const sl_el = el.querySelector('.js-sl');

    const sl = new Swiper(sl_el, {
      slidesPerView: 'auto',
      slideActiveClass: 'is-active',
      slideNextClass: 'is-next',
      slidePrevClass: 'is-prev',
      slideVisibleClass: 'is-visible',
      navigation: {
        nextEl: btn_next,
        prevEl: btn_prev,
        disabledClass: 'is-disabled'
      },
      pagination: {
        el: '.js-pagination',
        type: 'bullets',
        bulletActiveClass: 'is-active',
        bulletClass: 'bullet',
        currentClass: 'is-current',
        clickable: true,
        modifierClass: 'bullets-'
      },
      breakpoints: {
        769: {
          centeredSlides: true,
          initialSlide: 1
        }
      }
    });

    const filterSlides = sw => {
      const cur = el.querySelector('.js-filter.is-active');
      const cur_value = cur.dataset.filter;

      let hidden = [];
      let visible = [];

      sw.slides.forEach(slide => {
        if (slide.classList.contains(cur_value)) {
          slide.style.display = 'block';
          visible.push(slide);
        } else {
          slide.style.display = 'none';
          hidden.push(slide);
        }
      });

      sw.wrapperEl.innerHTML = '';

      visible.forEach(el => {
        sw.wrapperEl.appendChild(el);
      });

      hidden.forEach(el => {
        sw.wrapperEl.appendChild(el);
      });

      sw.update();
      if (window.innerWidth > 768) {
        sw.slideTo(1);
      } else {
        sw.slideTo(0);
      }
    };

    filterSlides(sl);

    const btns = Array.from(document.querySelectorAll('.js-filter'));

    btns.forEach(btn => {
      btn.addEventListener('click', e => {
        btns.forEach(el => el.classList.remove('is-active'));
        btn.classList.add('is-active');

        filterSlides(sl);
      });
    })

  }

  slBanks() {
    if (window.innerWidth < 768) return;

    const el = document.querySelector('#sl-banks');

    if (!el) return;

    const btn_prev = el.querySelector('.js-prev');
    const btn_next = el.querySelector('.js-next');

    const sl = new Swiper(el.querySelector('.js-sl'), {
      slidesPerView: 'auto',
      slideActiveClass: 'is-active',
      slideNextClass: 'is-next',
      slidePrevClass: 'is-prev',
      navigation: {
        nextEl: btn_next,
        prevEl: btn_prev,
        disabledClass: 'is-disabled'
      },
      pagination: {
        el: '.js-pagination',
        type: 'bullets',
        bulletActiveClass: 'is-active',
        bulletClass: 'bullet',
        currentClass: 'is-current',
        clickable: true
      },
    });
  }

  slNews() {
    const el = document.querySelector('#sl-news');

    if (!el) return;

    const btn_prev = el.querySelector('.js-prev');
    const btn_next = el.querySelector('.js-next');

    const sl = new Swiper(el.querySelector('.js-sl'), {
      slidesPerView: 'auto',
      slideActiveClass: 'is-active',
      slideNextClass: 'is-next',
      slidePrevClass: 'is-prev',
      navigation: {
        nextEl: (btn_next) ? btn_next : null,
        prevEl: (btn_prev) ? btn_prev : null,
        disabledClass: 'is-disabled'
      },
      breakpoints: {
        769: {
          centeredSlides: true,
          initialSlide: 1
        }
      }
    });
  }

  slGallery() {
    const elems = Array.from(document.querySelectorAll('.js-sl-gallery'));

    if (elems.length == 0) return;

    window.AppSlGallery = [];

    elems.forEach((el, idx) => {
      const sl = new Swiper(el, {
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.js-next',
          prevEl: '.js-prev',
          disabledClass: 'is-disabled'
        },
        pagination: {
          el: '.js-pagination',
          type: 'bullets',
          bulletActiveClass: 'is-active',
          bulletClass: 'bullet',
          currentClass: 'is-current',
          clickable: true
        },
      });

      window.AppSlGallery.push(sl);
    });
  }

  slProcess() {
    if (window.innerWidth < 769) return;

    const el = document.querySelector('#sl-process');

    if (!el) return;

    const btn_prev = el.querySelector('.js-prev');
    const btn_next = el.querySelector('.js-next');

    window.AppSlProjectProcess = new Swiper(el.querySelector('.js-sl'), {
      slidesPerView: 'auto',
      navigation: {
        nextEl: (btn_next) ? btn_next : null,
        prevEl: (btn_prev) ? btn_prev : null,
        disabledClass: 'is-disabled'
      }
    });
  }

  slPlans() {
    const elems = Array.from(document.querySelectorAll('.js-sl-plans'));

    if (elems.length == 0) return;

    window.AppSlPlan = [];

    elems.forEach(el => {
      const sl_el = el.querySelector('.js-sl');
      const thumb_el = el.querySelector('.js-thumb');

      const activateSlide = sw => {
        const el = sw.el.closest('.js-sl-plans');

        if (sw.slides.length == 0) return;

        const link = sw.slides[sw.activeIndex].getAttribute('data-file');

        const link_el = el.querySelector('.js-link');
        link_el.setAttribute('href', link);

        const thumb_el = el.querySelector('.js-thumb');
        const thumb_btns = Array.from(thumb_el.querySelectorAll('button'));

        thumb_btns.forEach(item => item.classList.remove('is-active'));
        thumb_btns[sw.activeIndex].classList.add('is-active');
      }

      const sl = new Swiper(sl_el, {
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.js-next',
          prevEl: '.js-prev',
          disabledClass: 'is-disabled'
        },
        pagination: {
          el: '.js-pagination',
          type: 'bullets',
          bulletActiveClass: 'is-active',
          bulletClass: 'bullet',
          currentClass: 'is-current',
          clickable: true
        },
        on: {
          init: sw => {
            activateSlide(sw);
          },
          slideChangeTransitionStart: sw => {
            activateSlide(sw);
          },
          update: sw => {
            activateSlide(sw);
          }
        }
      });

      thumb_el.addEventListener('click', e => {
        if (!e.target.closest('button')) return;

        const index = e.target.getAttribute('data-index');

        sl.slideTo(index);
      });

      window.AppSlPlan.push(sl);
    });
  }
}

export default Slider;
